<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-exchange text-primary"></i>{{ $t('coin.100') }}</div>
		</div>
		<div class="section_right_inner">

			<div class="notice_table04">
					<div class="card mb-2 border-0 rounded-0 border-bottom border-gray-relative-300" v-for="(item,index) in exchange_history" :key="index">
						<div class="card-body px-0">
							<div class="progress bg-gray-relative-300">
								<div class="progress-bar" role="progressbar" :style="{'width': item.percent_text}" :aria-valuenow="item.percent" aria-valuemin="0" aria-valuemax="100">{{numberWithCommas(item.use_balance)}}</div>
							</div>
							<div class="d-flex flex-between-center mt-1">
								<div><h6 class="fw-bold text-gray-relative-900">{{ $t('coin.101') }}</h6></div>
								<div><small class="fs-px-12 text-gray-500">{{item.unlock_date}} </small><small class="fs-px-12 text-gray-500" v-if="item.day >0 ">( D-{{ item.day }} ) </small></div>
							</div>
							<div class="d-flex flex-between-center mt-1">
								<div><h6 class="fw-bold text-gray-relative-900">{{ $t('history.8') }}</h6></div>
								<div><small class="fs-px-12 text-gray-500">{{numberWithCommas(item.trans_balance)}}</small></div>
							</div>
							<div class="d-flex flex-between-center mt-1">
								<div><h6 class="fw-bold text-gray-relative-900">{{ $t('coin.102') }}</h6></div>
								<div><small class="fs-px-12 text-gray-500">{{numberWithCommas(item.day_balance)}}</small></div>
							</div>
						</div>
					</div>
					
				</div>	
		</div>
		

		
	</div>
	<!--section_right end-->
</template>
<script>
const exactMath = require('exact-math');
const CryptoJS = require("crypto-js");
import VueQrcode from 'vue-qrcode'
	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				exchange_history : [],
			}
		},
	    mounted(){
			this.GetCoinExchangeInfo();
			
		},
		methods: {
			
			GetCoinExchangeInfo(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/coin/GetCoinExchangeInfo`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								this.exchange_history = body.list;
								
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
            comma(str) {
                str = String(str);
                return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
            },
            uncomma(str) {
                str = String(str);
                return str.replace(/[^\d]+/g, '');
            },
            inputNumberFormat(){
                const price = this.t_balance;
                const uncomma = this.uncomma(price);
                this.t_balance = this.comma(uncomma);
            },
			numberWithCommas(x) {
				var parts=x.toString().split(".");
				return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
			},
		}
	}
</script>
<style>
</style>