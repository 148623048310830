import { render, staticRenderFns } from "./CoinHistory.vue?vue&type=template&id=633aad78&scoped=true"
import script from "./CoinHistory.vue?vue&type=script&lang=js"
export * from "./CoinHistory.vue?vue&type=script&lang=js"
import style0 from "./CoinHistory.vue?vue&type=style&index=0&id=633aad78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "633aad78",
  null
  
)

export default component.exports