<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-exchange text-primary"></i>{{$t('withdraw_coin.1')}}</div>
		</div>
		<div class="section_right_inner">
			


			<div class="s_left">
				<!--s_left-->
				<div class="withdrawal_section01">
					<!--withdrawal_section01-->
					<p class="withdrawal_section01_t"><span>{{$t('withdraw_coin.100')}}</span></p>
					<!--total_box end-->
					<div name="packagefrm">
						<div class="withdrawal_input_box">
							<!--withdrawal_input_box-->
							<table style="width:100%;">
								<tbody>
                                    <tr>
										<td>{{$t('deposit.5')}}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="numberWithCommas(coin)" readonly="">
											</div>
											
										</td>
									</tr>
                                    <tr>
										<td>{{ $t('withdraw_point.30') }}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" v-model="t_balance" @keyup="inputNumberFormat()" >
											</div>
											
										</td>
									</tr>
                                </tbody>
                            </table>
                            
							<div><button type="button" id="next" class="btn-qrcode" @click="SendConfirm()">{{$t('withdraw_coin.6')}}</button></div>
						</div>
					</div>
				</div>
				<!--withdrawal_section01-->

				
			</div>
			
		</div>
		

		
	</div>
	<!--section_right end-->
</template>
<script>
const exactMath = require('exact-math');
const CryptoJS = require("crypto-js");
import VueQrcode from 'vue-qrcode'
	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				t_balance : '',
                coin : '',
				w_able : 'N',
			}
		},
	    mounted(){
			this.GetCoinBalance();
			this.GetAble()
		},
		methods: {
			GetAble(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/point/GetAble`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.w_able = body.info.withdraw;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			SendConfirm(){
                const t_balance = this.t_balance;
                const balance = this.uncomma(t_balance);
                const point = this.coin;
				const w_able = this.w_able;

				if(w_able =='N'){
                    this.$alert(this.$t('withdraw_coin.16'))
                    return false;
                }

                if(balance ==''){
                    this.$alert(this.$t('withdraw_coin.101'))
                    return false;
                }
                const c_balance = exactMath.sub(point,balance);

                if(c_balance <0){
                    this.$alert(this.$t('withdraw_coin.101'))
                    return false;
                }

                
                this.Send();

            },
            Send(){
                const t_balance = this.t_balance;
                const balance = this.uncomma(t_balance);
                
				const body = {balance};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/coin/Send`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
                                this.$alert(this.$t('withdraw_point.24')).then(
                                    ()=>{
                                        this.$router.push({path:`/${this.$i18n.locale}/dashboard`})
                                    }
                                )


							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
				
            },
			GetCoinBalance(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/coin/GetCoinPointBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.coin = body.coin;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
				
			},
            
			comma(str) {
                str = String(str);
                return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
            },
            uncomma(str) {
                str = String(str);
                return str.replace(/[^\d]+/g, '');
            },
            inputNumberFormat(){
                const price = this.t_balance;
                const uncomma = this.uncomma(price);
                this.t_balance = this.comma(uncomma);
            },
			numberWithCommas(x) {
				var parts=x.toString().split(".");
				return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
			},
		}
	}
</script>
<style>
</style>